<template>
  <div>
    <div class="p-banner">
      <img alt="" class="p-banner-bg" src="../../assets/img/service/banner.png">
      <div class="container" v-animate-on-intersect>
        <div class="p-banner-title">{{ menuType == 1 ? '工艺开发与测试' : menuType == 2 ? '工程设计' : menuType == 3 ? '设备制造与集成' : '交付与现场服务'}}</div>
        <div class="p-banner-sub-title">{{ menuType == 1 ? '工艺开发与测试' : menuType == 2 ? '工程设计' : menuType == 3 ? '设备制造与集成' : '交付与现场服务'}}</div>
      </div>
    </div>
    <div class="page-menu service-page-menu page-menu-xs d-flex-center">
      <div :class="menuType == 1 ? 'on' : ''" @click="menuType = 1">工艺开发与测试</div>
      <div :class="menuType == 2 ? 'on' : ''" @click="menuType = 2">工程设计</div>
      <div :class="menuType == 3 ? 'on' : ''" @click="menuType = 3">设备制造与集成</div>
      <div :class="menuType == 4 ? 'on' : ''" @click="menuType = 4">交付与现场服务</div>
    </div>
    <div v-if="menuType == 1" class="container">
      <div class="product-item-title">概述</div>
      <div class="grey-box page-text mb-40" v-animate-on-intersect>对任何一个具体的分离体系和分离任务，通常有多种方法可以选择，最佳方案的获取，需要借助高质量的开发研究和多次的测试。</div>
      <div class="product-item-title">开发研究</div>
      <div class="page-text mb-15">开发研究在专业的知识、丰富的经验和多年积累数据的基础上进行，借助先进的流程模拟工具，我们可以对各种可能方案的投资、能耗、产品质量和运行成本等进行对比分析，从而获取理想的解决方案。 在开发研究中，通过公开文献和已有数据库等不能获取的数据，我们将通过研发中心的检测和实验来获取。</div>
      <div class="preview-image mb-40"><img class="page-img "  v-image-preview v-animate-on-intersect src="../../assets/img/service/page1-7.jpg" alt=""></div>
      <div class="product-item-title">开发结果</div>
      <div class="img-list mb-40" v-animate-on-intersect>
        <div class="page-text">开发结果将形成一份初步技术方案，为你提供你项目下一步决策所需要的所有信息，包括所采用的分离技术、预期的产品纯度、能耗和投资等详细信息。技术方案通常包含下列信息，我们将在开发完成后提供给您。</div>
        <div class="page-text f-bold"><img src="../../assets/img/product/item2-icon.png" >设计基础和假设条件</div>
        <div class="page-text f-bold"><img src="../../assets/img/product/item2-icon.png" >工艺描述</div>
        <div class="page-text f-bold"><img src="../../assets/img/product/item2-icon.png" >工艺流程简图</div>
        <div class="page-text f-bold"><img src="../../assets/img/product/item2-icon.png" >主要工艺数据</div>
        <div class="page-text f-bold"><img src="../../assets/img/product/item2-icon.png" >主要能量消耗</div>
        <div class="page-text f-bold"><img src="../../assets/img/product/item2-icon.png" >关键设备一览表</div>
        <div class="page-text f-bold"><img src="../../assets/img/product/item2-icon.png" >建议</div>
      </div>
      <div class="product-item-title">开发能力</div>
      <div class="page-text">我们的开发实验室位于重庆国际生物城，实验室根据现代化工过程开发所需软硬件设施进行建设，配备了反应动力学、反应热力学等反应开发设备和分子蒸馏、精馏、萃取、熔融结晶、升华结晶等分离测试设备，初步具备从实验室到第一套工业装置的全过程开发能力。</div>
      <el-row class="flex-warp" justify="space-between" type="flex" :gutter="50">
        <el-col :xs="12" :sm="12" :md="6" :lg="6" :xl="6">
          <div class="img-item">
            <div class="preview-image"><img class="page-img" v-image-preview src="../../assets/img/service/page1-1.png" alt=""></div>
            <div class="img-item-btm-title">工艺开发实验室</div>
          </div>
        </el-col>
        <el-col :xs="12" :sm="12" :md="6" :lg="6" :xl="6">
          <div class="img-item">
            <div class="preview-image"><img class="page-img" v-image-preview src="../../assets/img/service/page1-2.png" alt=""></div>
            <div class="img-item-btm-title">分析检测室</div>
          </div>
        </el-col>
        <el-col :xs="12" :sm="12" :md="6" :lg="6" :xl="6">
          <div class="img-item">
            <div class="preview-image"><img class="page-img" v-image-preview src="../../assets/img/service/page1-3.png" alt=""></div>
            <div class="img-item-btm-title">分离测试实验装置</div>
          </div>
        </el-col>
        <el-col :xs="12" :sm="12" :md="6" :lg="6" :xl="6">
          <div class="img-item">
            <div class="preview-image"><img class="page-img" v-image-preview src="../../assets/img/service/page1-4.png" alt=""></div>
            <div class="img-item-btm-title">固定床催化反应实验装置</div>
          </div>
        </el-col>
      </el-row>
      <div class="page-text">我们的中试及产业化示范基地位于贵州丹寨工业园区，基地配备了较为完备的公用工程和污水处理系统，可方便快捷实施中试试验和产业化示范运营。</div>
      <el-row class="flex-warp mb-40" justify="space-between" type="flex" :gutter="50">
        <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
          <div class="img-item">
            <div class="preview-image"><img class="page-img" v-image-preview src="../../assets/img/service/page1-5.png" alt=""></div>
            <div class="img-item-btm-title">中试车间</div>
          </div>
        </el-col>
        <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
          <div class="img-item">
            <div class="preview-image"><img class="page-img" v-image-preview src="../../assets/img/service/page1-6.png" alt=""></div>
            <div class="img-item-btm-title">中试车间制氮系统</div>
          </div>
        </el-col>
      </el-row>
    </div>
    <div v-else-if="menuType == 2" class="container page-gcsj">
      <div class="product-item-title">概述</div>
      <div class="grey-box page-text mb-40" v-animate-on-intersect>无论是为您提供关键专用工艺设备，还是提供成套工艺装置，我们可以根据您的要求，向您提供基础工程设计包和详细工程设计文件。</div>
      <div class="product-item-title">基础工程设计</div>
      <div class="page-text mb-15">基础工程设计包将为一个新装置的建设提供必要的工艺技术信息，基础工程设计包的内容和深度将根据客户的具体要求确定。我们的基础工程设计包内容通常包括：</div>
      <el-row class="flex-warp mb-40 radius-10 box-border" v-animate-on-intersect justify="space-between" type="flex">
        <el-col :lg="12" :md="12" :sm="12" :xl="12" :xs="24">
          <div class="preview-image"><img class="page-img" style="height: 100%" src="../../assets/img/service/page2-1.png" v-image-preview></div>
        </el-col>
        <el-col :lg="12" :md="12" :sm="12" :xl="12" :xs="24">
          <div class="dot-box">
            <div class="dot-list"><img src="../../assets/img/product/item2-icon.png" alt="">工艺描述</div>
            <div class="dot-list"><img src="../../assets/img/product/item2-icon.png" alt="">工艺流程图 (PFD)</div>
            <div class="dot-list"><img src="../../assets/img/product/item2-icon.png" alt="">物料和能量平衡</div>
            <div class="dot-list"><img src="../../assets/img/product/item2-icon.png" alt="">公用工程一览表</div>
            <div class="dot-list"><img src="../../assets/img/product/item2-icon.png" alt="">管道仪表流程图(P&ID)</div>
            <div class="dot-list"><img src="../../assets/img/product/item2-icon.png" alt="">设备数据表</div>
            <div class="dot-list"><img src="../../assets/img/product/item2-icon.png" alt="">仪表数据表</div>
            <div class="dot-list"><img src="../../assets/img/product/item2-icon.png" alt="">工艺控制描述</div>
            <div class="dot-list"><img src="../../assets/img/product/item2-icon.png" alt="">初步的设备布置图</div>
            <div class="dot-list"><img src="../../assets/img/product/item2-icon.png" alt="">初步的操作手册</div>
          </div>
        </el-col>
      </el-row>
      <div class="product-item-title">详细工程设计</div>
      <div class="page-text mb-15">当我们为您提供成套工艺装置时，我们将同步向您提供详细工程设计文件，详细工程设计文件通常包括以下内容的全部或几项：</div>
      <div class="page-text mb-15">（1）工艺专业</div>
      <el-row class="flex-warp mb-25 radius-10 box-border" v-animate-on-intersect justify="space-between" type="flex">
        <el-col :lg="12" :md="12" :sm="12" :xl="12" :xs="24">
          <div class="preview-image"><img class="page-img" style="height: 100%" v-image-preview src="../../assets/img/service/page2-2.png" ></div>
        </el-col>
        <el-col :lg="12" :md="12" :sm="12" :xl="12" :xs="24">
          <div class="dot-box">
            <div class="dot-list"><img src="../../assets/img/product/item2-icon.png" alt="">管道及仪表流程图（PID）</div>
            <div class="dot-list"><img src="../../assets/img/product/item2-icon.png" alt="">公用工程管道及仪表流程图（UID）</div>
            <div class="dot-list"><img src="../../assets/img/product/item2-icon.png" alt="">物料平衡图（PFD）</div>
            <div class="dot-list"><img src="../../assets/img/product/item2-icon.png" alt="">公用工程平衡图（UFD）</div>
            <div class="dot-list"><img src="../../assets/img/product/item2-icon.png" alt="">管道仪表流程图(P&ID)</div>
            <div class="dot-list"><img src="../../assets/img/product/item2-icon.png" alt="">工艺概述</div>
            <div class="dot-list"><img src="../../assets/img/product/item2-icon.png" alt="">设备清单（材质、型号、规格、重量、尺寸、</div>
            <div class="dot-list"><img src="../../assets/img/product/item2-icon.png" alt="">管道一览表（含压力管道标识）</div>
            <div class="dot-list"><img src="../../assets/img/product/item2-icon.png" alt="">设备数据单</div>
            <div class="dot-list"><img src="../../assets/img/product/item2-icon.png" alt="">设备荷载表</div>
            <div class="dot-list"><img src="../../assets/img/product/item2-icon.png" alt="">联锁关系表</div>
          </div>
        </el-col>
      </el-row>
      <div class="page-text mb-15">（2）设备专业</div>
      <el-row class="flex-warp mb-25 radius-10 box-border" v-animate-on-intersect justify="space-between" type="flex">
        <el-col :lg="12" :md="12" :sm="12" :xl="12" :xs="24">
           <div class="preview-image"><img class="page-img" style="height: 100%" v-image-preview src="../../assets/img/service/page2-6.png"></div>
        </el-col>
        <el-col :lg="12" :md="12" :sm="12" :xl="12" :xs="24">
          <div class="dot-box">
            <div class="dot-list"><img src="../../assets/img/product/item2-icon.png" alt="">设备外形图（包括总装图、零部件图、预焊件</div>
            <div class="dot-list"><img src="../../assets/img/product/item2-icon.png" alt="">强度计算书</div>
            <div class="dot-list"><img src="../../assets/img/product/item2-icon.png" alt="">隔热层支撑件设计条件图</div>
            <div class="dot-list"><img src="../../assets/img/product/item2-icon.png" alt="">动设备数据单</div>
            <div class="dot-list"><img src="../../assets/img/product/item2-icon.png" alt="">成套设备数据单</div>
          </div>
        </el-col>
      </el-row>
      <div class="page-text mb-15">（3）电气专业</div>
      <el-row class="flex-warp mb-25 radius-10 box-border" v-animate-on-intersect justify="space-between" type="flex">
        <el-col :lg="12" :md="12" :sm="12" :xl="12" :xs="24">
           <div class="preview-image"><img class="page-img" style="height: 100%" v-image-preview src="../../assets/img/service/page2-3.png"></div>
        </el-col>
        <el-col :lg="12" :md="12" :sm="12" :xl="12" :xs="24">
          <div class="dot-box">
            <div class="dot-list"><img src="../../assets/img/product/item2-icon.png" alt="">用电设备表</div>
            <div class="dot-list"><img src="../../assets/img/product/item2-icon.png" alt="">电气原理图</div>
            <div class="dot-list"><img src="../../assets/img/product/item2-icon.png" alt="">电气设备接线图</div>
            <div class="dot-list"><img src="../../assets/img/product/item2-icon.png" alt="">设备接地点位置图</div>
            <div class="dot-list"><img src="../../assets/img/product/item2-icon.png" alt="">电缆表及布线图</div>
            <div class="dot-list"><img src="../../assets/img/product/item2-icon.png" alt="">装置照明设计</div>
            <div class="dot-list"><img src="../../assets/img/product/item2-icon.png" alt="">综合材料表</div>
            <div class="dot-list"><img src="../../assets/img/product/item2-icon.png" alt="">变频设备相关信息（如电机转速、减速机减速</div>
          </div>
        </el-col>
      </el-row>
      <div class="page-text mb-15">（4）仪表专业</div>
      <el-row class="flex-warp mb-25 radius-10 box-border" v-animate-on-intersect justify="space-between" type="flex">
        <el-col :lg="12" :md="12" :sm="12" :xl="12" :xs="24">
           <div class="preview-image"><img class="page-img" style="height: 100%" v-image-preview src="../../assets/img/service/page2-4.png"></div>
        </el-col>
        <el-col :lg="12" :md="12" :sm="12" :xl="12" :xs="24">
          <div class="dot-box">
            <div class="dot-list"><img src="../../assets/img/product/item2-icon.png" alt="">仪表清单（包括仪表位号、型号、测量范围、</div>
            <div class="dot-list"><img src="../../assets/img/product/item2-icon.png" alt="">自动控制方案说明</div>
            <div class="dot-list"><img src="../../assets/img/product/item2-icon.png" alt="">自动联锁工艺说明</div>
            <div class="dot-list"><img src="../../assets/img/product/item2-icon.png" alt="">DCS I/O 表</div>
            <div class="dot-list"><img src="../../assets/img/product/item2-icon.png" alt="">仪表配管配线平面布置图</div>
            <div class="dot-list"><img src="../../assets/img/product/item2-icon.png" alt="">仪表回路图</div>
            <div class="dot-list"><img src="../../assets/img/product/item2-icon.png" alt="">仪表供电系统图</div>
            <div class="dot-list"><img src="../../assets/img/product/item2-icon.png" alt="">仪表接地系统图</div>
            <div class="dot-list"><img src="../../assets/img/product/item2-icon.png" alt="">可燃、有毒气体报警器分布图</div>
            <div class="dot-list"><img src="../../assets/img/product/item2-icon.png" alt="">报警和联锁一览表</div>
            <div class="dot-list"><img src="../../assets/img/product/item2-icon.png" alt="">综合材料表</div>
          </div>
        </el-col>
      </el-row>
      <div class="page-text mb-15">（5）管道及布置专业</div>
      <el-row class="flex-warp mb-25 radius-10 box-border" v-animate-on-intersect justify="space-between" type="flex">
        <el-col :lg="12" :md="12" :sm="12" :xl="12" :xs="24">
           <div class="preview-image"><img class="page-img" style="height: 100%" v-image-preview src="../../assets/img/service/page2-5.png"></div>
        </el-col>
        <el-col :lg="12" :md="12" :sm="12" :xl="12" :xs="24">
          <div class="dot-box">
            <div class="dot-list"><img src="../../assets/img/product/item2-icon.png" alt="">设备基础图</div>
            <div class="dot-list"><img src="../../assets/img/product/item2-icon.png" alt="">操作检修平台及斜爬梯的设计条件图</div>
            <div class="dot-list"><img src="../../assets/img/product/item2-icon.png" alt="">设备布置图</div>
            <div class="dot-list"><img src="../../assets/img/product/item2-icon.png" alt="">土建条件图</div>
            <div class="dot-list"><img src="../../assets/img/product/item2-icon.png" alt="">综合材料表</div>
            <div class="dot-list"><img src="../../assets/img/product/item2-icon.png" alt="">阀门规格书</div>
            <div class="dot-list"><img src="../../assets/img/product/item2-icon.png" alt="">管道支吊架表</div>
            <div class="dot-list"><img src="../../assets/img/product/item2-icon.png" alt="">管道支吊架图</div>
            <div class="dot-list"><img src="../../assets/img/product/item2-icon.png" alt="">管道配管图及单线图</div>
            <div class="dot-list"><img src="../../assets/img/product/item2-icon.png" alt="">管道伴热表</div>
            <div class="dot-list"><img src="../../assets/img/product/item2-icon.png" alt="">伴热管道系统图</div>
            <div class="dot-list"><img src="../../assets/img/product/item2-icon.png" alt="">保温一览表</div>
            <div class="dot-list"><img src="../../assets/img/product/item2-icon.png" alt="">安全淋浴、洗眼器分布图</div>
          </div>
        </el-col>
      </el-row>
    </div>
    <div v-else-if="menuType == 3" class="container">
      <div class="product-item-title">概述</div>
      <div class="page-text mb-15">专用工艺设备的制造遵循严格的质量管理流程。成套设备集合了设备、管道、阀门、结构、电气和仪表。设备集成工作在设计、采购的基础上进行，首先进行基础结构的制造与装配，其后在基础结构上安装设备、管道、阀门和电气仪表等，制造完成之后进行检验和测试，以确保满足工艺的要求。</div>
      <el-row class="flex-warp mb-40 radius-10 box-shadow" v-animate-on-intersect justify="space-between" type="flex">
        <el-col :lg="10" :md="10" :sm="10" :xl="10" :xs="24">
          <div class="preview-image"><img class="box-img" v-image-preview style="height: 100%" src="../../assets/img/service/page3-1.png"></div>
        </el-col>
        <el-col :lg="14" :md="14" :sm="14" :xl="14" :xs="24">
          <div class="dot-box">
            <div class="dot-list f-bold">一般的设备制造与集成工作包括以下几项（不限于）：</div>
            <div class="dot-list"><img src="../../assets/img/product/item2-icon.png" alt="">制造及组装</div>
            <div class="dot-list"><img src="../../assets/img/product/item2-icon.png" alt="">工厂检验和测试</div>
            <div class="dot-list"><img src="../../assets/img/product/item2-icon.png" alt="">涂漆</div>
            <div class="dot-list"><img src="../../assets/img/product/item2-icon.png" alt="">包装</div>
            <div class="dot-list"><img src="../../assets/img/product/item2-icon.png" alt="">根据交货要求的发货及运输</div>
          </div>
        </el-col>
      </el-row>
      <div class="product-item-title">设备制造与集成能力</div>
      <div class="page-text">公司设备制造及装置集成基地分布于安徽宣城、福建三明、江苏无锡和重庆北碚，可就近为客户提供设备制造和装置成套服务。</div>
      <el-row class="flex-warp mb-40" justify="space-between" type="flex" :gutter="50" v-animate-on-intersect>
        <el-col :xl="12" :lg="12" :md="12" :sm="24" :xs="24">
          <div class="service-item radius-10 box-shadow">
            <div class="preview-image"><img class="page-img" style="height: 100%" v-image-preview src="../../assets/img/service/page3-2.png"></div>
            <div class="name">安徽宣城基地</div>
            <div class="desc">厂房面积二万三千平方米，拥有7条全自动激光焊接生产线，年产熔融结晶器400台套</div>
          </div>
        </el-col>
        <el-col :xl="12" :lg="12" :md="12" :sm="24" :xs="24">
          <div class="service-item radius-10 box-shadow">
             <div class="preview-image"><img class="page-img" style="height: 100%" v-image-preview src="../../assets/img/service/page3-3.png"></div>
            <div class="name">福建三明基地</div>
            <div class="desc">厂房面积一万四千平方米，具备优良的反应器/塔/换热器制造能力</div>
          </div>
        </el-col>
        <el-col :xl="12" :lg="12" :md="12" :sm="24" :xs="24">
          <div class="service-item radius-10 box-shadow">
             <div class="preview-image"><img class="page-img" style="height: 100%" v-image-preview src="../../assets/img/service/page3-4.png"></div>
            <div class="name">江苏无锡基地</div>
            <div class="desc">厂房面积一万两千平方米，可承接GB、ASME、PED等不同设计、制造标准的设备、撬块</div>
          </div>
        </el-col>
        <el-col :xl="12" :lg="12" :md="12" :sm="24" :xs="24">
          <div class="service-item radius-10 box-shadow">
             <div class="preview-image"><img class="page-img" style="height: 100%" v-image-preview src="../../assets/img/service/page3-5.png"></div>
            <div class="name">重庆北碚基地</div>
            <div class="desc">厂房面积一万五千余平方米，拥有无损检测、管道预制、撬块制造等设施和队伍</div>
          </div>
        </el-col>
      </el-row>
    </div>
    <div v-else-if="menuType == 4" class="container">
      <div class="product-item-title">概述</div>
      <div class="page-text mb-15" v-animate-on-intersect>无论是为您提供专用工艺设备，还是提供成套工艺装置，完美的交付和周详而细致的现场服务是实现目标的关键。
        <br>专用工艺设备或成套工艺装置制造完成后，我们将尽可能整体运输至客户现场，不能整体运输的，我们将会在完成整体性能测试后拆分为几个部分，妥善包装后运输到现场再进行二次组装和测试；现场安装系统将会
        <br>以预制件形式进行组装，尽可能减少现场施工时间。一切就绪后，我们的调试工程师现场指导调试和过程操作优化，为客户进行员工培训，直至设备能够稳定良好的运行。</div>
      <div v-animate-on-intersect>
        <el-row class="flex-warp mb-40 radius-10 box-shadow"   justify="space-between" type="flex">
          <el-col :lg="10" :md="10" :sm="10" :xl="10" :xs="24">
            <div class="preview-image"><img class="box-img" style="height: 100%" v-image-preview src="../../assets/img/service/page4-1.png"></div>
          </el-col>
          <el-col :lg="14" :md="14" :sm="14" :xl="14" :xs="24">
            <div class="dot-box">
              <div class="dot-list f-bold">通常的交付及现场服务工作包括以下几项（不限于）：</div>
              <div class="dot-list"><img src="../../assets/img/product/item2-icon.png" alt="">培训</div>
              <div class="dot-list"><img src="../../assets/img/product/item2-icon.png" alt="">到进货验</div>
              <div class="dot-list"><img src="../../assets/img/product/item2-icon.png" alt="">安装指导和检查</div>
              <div class="dot-list"><img src="../../assets/img/product/item2-icon.png" alt="">DCS组态编程及测试</div>
              <div class="dot-list"><img src="../../assets/img/product/item2-icon.png" alt="">调试及开车指导</div>
              <div class="dot-list"><img src="../../assets/img/product/item2-icon.png" alt="">配合性能考核及验收</div>
              <div class="dot-list"><img src="../../assets/img/product/item2-icon.png" alt="">定期的维护提醒和回访</div>
              <div class="dot-list"><img src="../../assets/img/product/item2-icon.png" alt="">事故响应服务</div>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "我们的服务详情",
  data(){
    return{
      menuType:1
    }
  },
  mounted() {
    if(this.$route.query.type) this.menuType = this.$route.query.type
  },
  beforeRouteEnter(to, from, next) {
    let _that = this
    next(vm => {
      // 在路由进入前滚动到顶部
      window.scrollTo(0, 0);
    });
  },
  beforeRouteUpdate(to, from, next) {
    if(to.query.type) this.menuType = to.query.type
    // 在当前路由改变，但是该组件被复用时滚动到顶部
    this.scrollToTop();
    next();
  },
  methods: {
    scrollToTop() {
      window.scrollTo(0, 0);
    }
  }
}
</script>

<style>
@import "../../assets/css/product.css";
</style>
